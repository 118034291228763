import * as React from 'react';
import { Complexity, Nation, VehicleType } from '~/vortex';
import { State } from '~/Reducers';
import { shallowEqual, useSelector } from 'react-redux';
import styles from '~/Components/Filter/Filter.scss';
import { FILTER_TITLE, CREW_TYPE_FILTER_TITLE } from '~/helpers/consts';
import { NAME_BACKGROUND_FILTER, NAME_PATCH_FILTER, NAME_SYMBOL_FILTER } from '~/Actions/ActionFilter';
import { t } from '~/helpers/localization';
import {
    COMPLEXITY_SHIP_NAME_FILTER,
    CREW_CATEGORY_NAME_FILTER,
    CREW_TYPE_NAME_FILTER,
    DOLL_CATEGORY_NAME_FILTER,
    ENSIGN_CATEGORY_NAME_FILTER,
    FILTERS_NAMES,
    NATION_NAME_FILTER,
    TYPE_SHIP_NAME_FILTER,
} from '~/Components/Filter/filtersMap';
import classNames from 'classnames';
import DivTooltip from '@wg/wows-react-uikit/DivTooltip';
import DefaultTooltip from '~/Components/Tooltip/DefaultTooltip';

interface IRenderFilterByType {
    filterName: FILTERS_NAMES;
    value: any;
}

interface IRenderFilterState {
    vehicleTypes: VehicleType[];
    nations: Nation[];
    complexities: Complexity[];
}

const stateSelectorRenderFilter = (state: State): IRenderFilterState => {
    return {
        vehicleTypes: state.ReducerApp.response.vehicleTypes,
        nations: state.ReducerApp.response.nations,
        complexities: state.ReducerApp.response.complexities,
    };
};

const getItemByKey = (arr: any[], key: string, value: any) => {
    return arr.filter((item) => item[key] === value)[0];
};

const RenderFilterByType = ({ filterName, value }: IRenderFilterByType) => {
    const appState = useSelector<State, IRenderFilterState>(stateSelectorRenderFilter, shallowEqual);

    switch (filterName) {
        case COMPLEXITY_SHIP_NAME_FILTER: {
            const complexity = getItemByKey(appState.complexities, 'level', Number(value));
            const complexityIconsWrapperStyles = classNames(styles.complexityIconsWrapper, styles.activeComplexityIconsWrapper);
            return (
                <DivTooltip className={complexityIconsWrapperStyles} tooltipBody={<DefaultTooltip text={complexity.header} />}>
                    {new Array(3).fill(0).map((_, index) => {
                        const iconStyles: React.CSSProperties = {
                            backgroundImage: `url(${index < complexity.level ? complexity.icons.filled : complexity.icons.empty})`,
                        };
                        return <div key={`complexity_icon_${index}`} className={styles.complexityIconWrapper} style={iconStyles} />;
                    })}
                </DivTooltip>
            );
        }

        case NATION_NAME_FILTER: {
            const nation = getItemByKey(appState.nations, 'name', value);

            const style: React.CSSProperties = {
                // @ts-ignore
                ['--backgroundImage']: `url(${nation.icons?.tiny})`,
            };
            return <DivTooltip className={styles.activeFilterNation} style={style} tooltipBody={<DefaultTooltip text={nation.title} />} />;
        }

        case TYPE_SHIP_NAME_FILTER:
            const shipType = getItemByKey(appState.vehicleTypes, 'name', value);

            return (
                <DivTooltip
                    tooltipBody={<DefaultTooltip text={shipType.title} />}
                    className={styles.activeFilterType}
                    style={{
                        backgroundImage: `url(${shipType.icons.default})`,
                    }}
                />
            );

        case ENSIGN_CATEGORY_NAME_FILTER:
        case CREW_CATEGORY_NAME_FILTER:
            return <div className={styles.activeItemFilter}>{FILTER_TITLE[value]}</div>;

        case CREW_TYPE_NAME_FILTER:
            return <div className={styles.activeItemFilter}>{CREW_TYPE_FILTER_TITLE[value]}</div>;

        case DOLL_CATEGORY_NAME_FILTER:
            let type;
            if (value === NAME_SYMBOL_FILTER) {
                type = t('Символ');
            } else if (value === NAME_BACKGROUND_FILTER) {
                type = t('Форма');
            } else if (value === NAME_PATCH_FILTER) {
                type = t('Нашивка');
            }

            return <div className={styles.activeItemFilter}>{type}</div>;

        default:
            return null;
    }
};

export default RenderFilterByType;
